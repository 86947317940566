/* fonte usada na logo */
@import url('https://fonts.googleapis.com/css2?family=Roboto+Slab&display=swap');

* {
  padding: 0px;
  margin: 0px;
  box-sizing: border-box;
  scrollbar-width: thin;
  scrollbar-color: #ff2c5f #333;
}

html {
  /* scroll-behavior: smooth; */
  overflow-x: hidden !important;
}

body {
  background: #111;
  color: #eee;
  font-family: 'Poppins', sans-serif;
  /* font-family: 'League Spartan', sans-serif; */
}

body::-webkit-scrollbar {
  width: 15px;
}

body::-webkit-scrollbar-track {
  background: #333;
}

body::-webkit-scrollbar-thumb {
  background-color: #ff2c5f;
  border-radius: 20px;
  border: 3px solid #000;
}

.swiper-button-prev {
  color: #ff2c5f;
}

.swiper-button-next {
  color: #ff2c5f;
}

.slide {
  width: 400px;
  height: 150px;
  color: #111;
  border-radius: 8px;
  border: 1px solid #ff2c5f;
  object-fit: cover;
  cursor: pointer;
}

.slide .image {
  width: 100%;
  height: 100%;
  border-radius: 8px;
}

#progress {
  position: fixed;
  bottom: 20px;
  right: 10px;
  height: 70px;
  width: 70px;
  display: none;
  place-items: center;
  border-radius: 50%;
  box-shadow: 0 0 10 rgba(0, 0, 0, 0.2);
  cursor: pointer;
  z-index: 1000;
}

@media screen and (max-width: 375px) {
  #progress {
    width: 50px;
    height: 50px;
  }
  #progress-value {
    font-size: 1.3rem !important;
  }
}

#progress-value {
  display: block;
  height: calc(100% - 10px);
  width: calc(100% - 10px);
  background-color: #000;
  border-radius: 50%;
  display: grid;
  place-items: center;
  font-size: 2rem;
}
